import { DictionaryType, FilterColor } from "@/shared/models";
import { LangService } from "@/shared/types/LangType";
import RuleModel, {
  DateRangeConditionType,
  RulePreview,
  RulePreviewType,
} from "@/ab-tests/models/RuleModel";

export default class RuleUtil {
  static getRulePreviews(
    {
      installDates,
      segments,
      countries,
      languages,
      deviceTypes,
      deviceModels,
      appVersions,
      osVersions,
      csvFileId,
      isLatEnabled,
      customProperties,
    }: RuleModel,
    lang: LangService
  ): Array<RulePreview> {
    return [
      ...(installDates
        ? [
            {
              [DateRangeConditionType.ABSOLUTE_VALUE]: {
                type: RulePreviewType.INSTALL_DATES,
                text:
                  installDates.minInstallDate === installDates.maxInstallDate
                    ? (installDates.minInstallDate as string)
                    : `${installDates.minInstallDate} — ${installDates.maxInstallDate}`,
                color: FilterColor.YELLOW,
              },
              [DateRangeConditionType.USERS_AGE]: {
                type: RulePreviewType.USERS_AGE,
                text: lang(
                  `rules.usersAge.${installDates.operator.toLowerCase()}`,
                  ...installDates.values
                ),
                color: FilterColor.YELLOW,
              },
            }[installDates.type],
          ]
        : []),
      ...(segments.length
        ? [
            {
              type: RulePreviewType.SEGMENTS,
              text: segments.length === 1 ? segments[0] : "",
              values: segments,
              dictionary: DictionaryType.SEGMENTS,
              color: FilterColor.RED,
            },
          ]
        : []),
      ...(countries.length
        ? [
            {
              type: RulePreviewType.COUNTRIES,
              text: countries.length === 1 ? countries[0] : "",
              values: countries,
              dictionary: DictionaryType.COUNTRIES,
              color: FilterColor.LIGHT_BLUE,
            },
          ]
        : []),
      ...(languages.length
        ? [
            {
              type: RulePreviewType.LANGUAGES,
              text: languages.length === 1 ? languages[0] : "",
              values: languages,
              dictionary: DictionaryType.LANGUAGES,
            },
          ]
        : []),
      ...(deviceTypes.length
        ? [
            {
              type: RulePreviewType.DEVICE_TYPES,
              text: deviceTypes.length === 1 ? deviceTypes[0] : "",
              values: deviceTypes,
              dictionary: DictionaryType.DEVICE_TYPES,
            },
          ]
        : []),
      ...(deviceModels.length
        ? [
            {
              type: RulePreviewType.DEVICE_MODELS,
              text: deviceModels.length === 1 ? deviceModels[0] : "",
              values: deviceModels,
              dictionary: DictionaryType.DEVICE_MODELS,
            },
          ]
        : []),
      ...(appVersions.length
        ? [
            {
              type: RulePreviewType.APP_VERSIONS,
              text: appVersions.length === 1 ? appVersions[0] : "",
              values: appVersions,
              dictionary: DictionaryType.APP_VERSIONS,
            },
          ]
        : []),
      ...(osVersions.length
        ? [
            {
              type: RulePreviewType.OS_VERSIONS,
              text: osVersions.length === 1 ? osVersions[0] : "",
              values: osVersions,
              dictionary: DictionaryType.OS_VERSIONS,
            },
          ]
        : []),
      ...(csvFileId !== null
        ? [
            {
              type: RulePreviewType.CSV_FILE_ID,
              text: String(csvFileId),
            },
          ]
        : []),
      ...(isLatEnabled !== null
        ? [
            {
              type: RulePreviewType.LAT,
              text: lang(`shared.boolNames.${isLatEnabled ? "on" : "off"}`),
            },
          ]
        : []),
      ...(customProperties.length
        ? [
            {
              type: RulePreviewType.CUSTOM_PROPERTIES,
              text: "",
              values: Object.entries(customProperties).map(
                ([key, value]) => `${key}:${value}`
              ),
            },
          ]
        : []),
    ];
  }
}
