var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-h6 mt-6",attrs:{"cols":"12","tag":"h2"}},[_vm._v(_vm._s(_vm.$lang("abTest.currentState.title")))]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',[_vm._l((_vm.steps),function(step,index){return [(index)?_c('v-divider',{key:("divider-" + index)}):_vm._e(),_c('v-stepper-step',{key:index,attrs:{"step":index + 1,"complete":_vm.currentStep > index + 1 || _vm.currentStep === _vm.steps.length}},[_vm._v(_vm._s(_vm.$lang( ((_vm.isTargeted ? "targetedConfig" : "abTest") + ".stepLabel." + (step.toLowerCase())) )))])]})],2),_c('v-stepper-items',[(_vm.isTargeted)?[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang(("targetedConfig.status." + (_vm.config.status.toLowerCase()))))+" ")]),_c('router-link',{attrs:{"to":{
                name: 'targeted_edit_configs',
                configId: _vm.config.id,
              }}},[_vm._v(_vm._s(_vm.$lang("targetedConfig.currentState.fillConfigs")))])],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang(("targetedConfig.status." + (_vm.config.status.toLowerCase()))))+" ")]),(!!_vm.config.activeSince)?_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$lang('targetedConfig.currentState.waiting', _vm.activeSince)
              )}}):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang("targetedConfig.currentState.active"))+" ")]),(!!_vm.config.activeTill)?_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$lang(
                  'targetedConfig.currentState.waitingToFinish',
                  _vm.activeTill
                )
              )}}):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang( "targetedConfig.currentState.configDistributionFinished" ))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$lang('targetedConfig.currentState.finished'))}})])]:[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang(("abTest.status." + (_vm.config.status.toLowerCase()))))+" ")]),_c('router-link',{attrs:{"to":{
                name: 'abTest_edit_configs',
                configId: _vm.config.id,
              }}},[_vm._v(_vm._s(_vm.$lang("abTest.currentState.fillConfigs")))])],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang(("abTest.status." + (_vm.config.status.toLowerCase()))))+" ")]),(_vm.isNewUsersType)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$lang('abTest.currentState.waiting', _vm.activeSince))}}):_vm._e()]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang("abTest.currentState.fillingGroupsHeading"))+" ")]),(_vm.isNewUsersType)?_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.$lang(
                  'abTest.currentState.fillingGroupsText',
                  _vm.fillnessPercent,
                  _vm.fillnessRemainder
                )
              )}}):_vm._e(),(_vm.isNewUsersType)?_c('v-progress-linear',{staticClass:"font-weight-bold text-body-2",attrs:{"value":+_vm.fillnessPercent,"color":"amber darken-1","height":"24","rounded":""}},[_vm._v(_vm._s(_vm.fillnessPercent)+"%")]):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang("abTest.currentState.observingHeading"))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.$lang('abTest.currentState.observingText', _vm.assignmentTill)
              )}}),_c('v-progress-linear',{staticClass:"font-weight-bold text-body-2",attrs:{"value":+_vm.daysLeftPercent,"color":"amber darken-1","height":"24","rounded":""}},[_vm._v(_vm._s(_vm.daysLeftMessage))])],1),_c('v-stepper-content',{attrs:{"step":"5"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$lang("abTest.currentState.observationFinished"))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.$lang('abTest.currentState.observationText', _vm.expectedDate)
              )}}),_c('v-progress-linear',{staticClass:"font-weight-bold text-body-2",attrs:{"value":+_vm.finishDaysLeftPercent,"color":"amber darken-1","height":"24","rounded":""}},[_vm._v(_vm._s(_vm.$lang("abTest.currentState.daysLeft", _vm.finishDaysLeft)))])],1),_c('v-stepper-content',{staticClass:"text-h6",attrs:{"step":"6"}},[_vm._v(" "+_vm._s(_vm.$lang("abTest.currentState.perfoming"))+" ")]),_c('v-stepper-content',{staticClass:"text-h6",attrs:{"step":"7"}},[_vm._v(" "+_vm._s(_vm.$lang("abTest.currentState.finished"))+" ")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }