





































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import ConfigRulePreview from "@/ab-tests/components/ConfigRulePreview.vue";
import ConfigCurrentState from "@/ab-tests/components/ConfigCurrentState.vue";
import AbTestSegmentStatistic from "@/ab-tests/components/AbTestSegmentStatistic.vue";
import AbTestConfigurationModel, {
  AbTestResultModel,
} from "@/ab-tests/models/AbTestConfigurationModel";
import RuleModel, {
  RuleOperator,
  RulePreview,
} from "@/ab-tests/models/RuleModel";
import { AppSection } from "@/shared/models";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import RuleUtil from "../utils/RuleUtil";

@Component({
  components: {
    ConfigRulePreview,
    ConfigCurrentState,
    AbTestSegmentStatistic,
  },
})
export default class AbTestView extends Vue {
  readonly AppSection = AppSection;
  readonly RuleOperator = RuleOperator;
  readonly STEPS = [
    ConfigsStatus.INIT,
    ConfigsStatus.SCHEDULED,
    ConfigsStatus.STARTED,
    ConfigsStatus.GROUPS_DISTRIBUTED,
    ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
    ConfigsStatus.STATISTICS_RECALCULATION,
    ConfigsStatus.STATISTICS_RECALCULATED,
  ];

  localAbTest = new AbTestConfigurationModel();
  isAbTestResultLoading = false;
  abTestResult: AbTestResultModel | null = null;

  get configId(): string {
    return this.$route.params.configId;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get loadingConfig(): boolean {
    return this.$store.state.abTestConfig.loadingConfig;
  }

  get abTest(): AbTestConfigurationModel {
    return this.$store.state.abTestConfig.abTestConfig;
  }

  get localRules(): Array<RuleModel> {
    return [...this.localAbTest.rules].sort((ruleA, ruleB) => {
      if (ruleA.operator === ruleB.operator) {
        return 0;
      }

      return ruleA.operator === RuleOperator.EXCLUDE ? -1 : 1;
    });
  }

  get isStatisticCalculated(): boolean {
    return this.localAbTest.isStatisticCalculated;
  }

  get isDisabled(): boolean {
    return this.localAbTest.isDisabled;
  }

  get isStarted(): boolean {
    return this.localAbTest.isStarted;
  }

  get assignmentTillUserResult(): number | undefined {
    return this.localAbTest.minUsersCount
      ? this.localAbTest.minUsersCount.amount *
          this.localAbTest.segmentStatistics.length
      : undefined;
  }

  get isCurrentStateVisible(): boolean {
    return (
      !!this.localAbTest.status &&
      [
        ConfigsStatus.INIT,
        ConfigsStatus.SCHEDULED,
        ConfigsStatus.STARTED,
        ConfigsStatus.GROUPS_DISTRIBUTED,
        ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
        ConfigsStatus.STATISTICS_RECALCULATION,
        ConfigsStatus.STATISTICS_RECALCULATED,
      ].includes(this.localAbTest.status)
    );
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    this.$store
      .dispatch("getAbTestConfig", this.configId)
      .then(() => {
        if (!this.isStatisticCalculated) {
          return;
        }

        this.isAbTestResultLoading = true;
        this.$store
          .dispatch("getAbTestResult", [this.configId])
          .then((result: Array<AbTestResultModel>) => {
            this.abTestResult = AbTestResultModel.of(result[0]);
          })
          .finally(() => {
            this.isAbTestResultLoading = false;
          });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: AppSection.AB_TESTS });
        }
      });
  }

  @Watch("abTest", { immediate: true })
  watchAbTest() {
    this.localAbTest = AbTestConfigurationModel.of(this.abTest);
  }

  toggleStatus() {
    this.$store.dispatch(
      this.localAbTest.isDisabled ? "enableAbTest" : "disableAbTest",
      {
        abTest: this.localAbTest,
      }
    );
  }

  getRulePreviews(rule: RuleModel): Array<RulePreview> {
    return RuleUtil.getRulePreviews(rule, this.$lang);
  }
}
