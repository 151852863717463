












































import { Component, Vue, Prop } from "vue-property-decorator";

import { RulePreview, RulePreviewType } from "@/ab-tests/models/RuleModel";
import { DictionaryType, FilterColor } from "@/shared/models";

@Component
export default class ConfigRulePreview extends Vue {
  @Prop() preview!: RulePreview;

  get color(): string {
    return `${this.preview.color ?? FilterColor.GREY} ${
      this.$vuetify.theme.dark ? "darken" : "lighten"
    }-3`;
  }

  get hasMultipleValue(): boolean {
    return !!(this.preview.values && this.preview.values.length > 1);
  }

  get isCsvFile(): boolean {
    return this.preview.type === RulePreviewType.CSV_FILE_ID;
  }

  get previewText(): string {
    let previewText = `<span class="font-weight-medium">${this.$lang(
      `rulePreview.${this.preview.type.toLowerCase()}`
    )}</span>`;

    if (this.preview.text) {
      previewText += `: ${this.processedText}`;
    }

    return previewText;
  }

  get baseUrl(): string {
    return this.axios.defaults.baseURL || "";
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get processedText(): string {
    return this.preview.dictionary
      ? this.getProcessedValue(this.preview.text)
      : this.preview.text;
  }

  getProcessedValue(value: string): string {
    switch (this.preview.dictionary) {
      case DictionaryType.LANGUAGES:
        return this.$store.getters.languagesNameByValue[value];
      case DictionaryType.DEVICE_TYPES:
        return this.$store.getters.deviceTypesNameByValue[value];
      case DictionaryType.SEGMENTS:
        return this.$store.getters.segmentsNameByValue[value];
      default:
        return value;
    }
  }
}
