

































































































































































































import DateUtil from "@/shared/utils/DateUtil";
import { Component, Vue, Prop } from "vue-property-decorator";
import AbTestConfigurationModel, {
  AbTestType,
} from "../models/AbTestConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Component
export default class ConfigCurrentState extends Vue {
  @Prop() config!: AbTestConfigurationModel;
  @Prop() steps!: Array<ConfigsStatus>;
  @Prop({ default: false }) isTargeted!: boolean;

  readonly EXPECTATION_DAYS_NUMBER = 2;

  get currentStep(): number {
    if (!this.config.status) {
      return 0;
    }

    return this.steps.findIndex((step) => step === this.config.status) + 1;
  }

  get isNewUsersType(): boolean {
    return this.config.abTestType === AbTestType.NEW_USERS;
  }

  get activeSince(): string {
    return DateUtil.formatDate(this.config.activeSince);
  }

  get activeTill(): string {
    return DateUtil.formatDate(this.config.activeTill);
  }

  get assignmentTill(): string {
    return DateUtil.formatDate(this.config.assignmentTillDate);
  }

  get expectedDate(): string {
    return this.config.assignmentTillDate
      ? DateUtil.formatDate(
          DateUtil.addDays(
            this.config.assignmentTillDate,
            this.EXPECTATION_DAYS_NUMBER + 1
          )
        )
      : "";
  }

  get totalUsersCount(): number {
    return (
      (this.config.minUsersCount?.amount ?? 0) *
      this.config.segmentStatistics.length
    );
  }

  get currentUsersCount(): number {
    return this.config.segmentStatistics.reduce(
      (sum, { usersCount }) => (sum += usersCount ?? 0),
      0
    );
  }

  get fillnessPercent(): string {
    return (
      this.totalUsersCount
        ? (this.currentUsersCount * 100) / this.totalUsersCount
        : 0
    ).toFixed(2);
  }

  get fillnessRemainder(): string {
    return (this.totalUsersCount - this.currentUsersCount).toLocaleString();
  }

  get daysCount(): number {
    return this.config.activeTill && this.config.assignmentTillDate
      ? DateUtil.getDaysDifference(
          this.config.activeTill,
          this.config.assignmentTillDate
        )
      : 0;
  }

  get daysDiffence(): number {
    if (!this.config.activeTill || !this.config.assignmentTillDate) {
      return 0;
    }

    return DateUtil.getDaysDifference(
      ConfigUtil.getCurrentDate(),
      this.config.assignmentTillDate
    );
  }

  get daysLeft(): number {
    return this.daysDiffence > 0 ? this.daysDiffence : 0;
  }

  get daysLeftMessage(): string {
    return this.config.activeTill &&
      this.config.assignmentTillDate &&
      this.daysDiffence === 0
      ? this.$lang("abTest.currentState.finishingToday")
      : this.$lang("abTest.currentState.daysLeft", this.daysLeft);
  }

  get daysLeftPercent(): string {
    return this.daysCount
      ? (((this.daysCount - this.daysLeft) * 100) / this.daysCount).toFixed(2)
      : "0";
  }

  get finishDaysLeft(): number {
    if (!this.expectedDate) {
      return 0;
    }

    const diff = DateUtil.getDaysDifference(
      ConfigUtil.getCurrentDate(),
      this.expectedDate
    );

    return diff > 0 ? diff : 0;
  }

  get finishDaysLeftPercent(): string {
    return (
      ((this.EXPECTATION_DAYS_NUMBER - this.finishDaysLeft) * 100) /
      this.EXPECTATION_DAYS_NUMBER
    ).toFixed(2);
  }
}
